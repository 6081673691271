import { colorBlueDark, mediaTabletLandscape } from "@10xdev/design-tokens";
import type { IconSource } from "@10xdev/types/models/IconSource";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../Anchor";
import Heading from "../Heading";
import Icon from "../Icon";
import Slash from "../Slash";
import Text from "../Text";

interface Props {
  description?: string;
  eyebrow?: string;
  heading?: string;
  headingSize?: "xxxlarge" | "xxlarge";
  link?: {
    label?: string;
    type?: IconSource;
    url?: string;
  };
  marginBottom?: string;
  narrow?: boolean;
  slash: boolean;
}

const columnCss = css`
  box-sizing: border-box;

  @media (max-width: ${mediaTabletLandscape}) {
    flex-basis: auto;
  }
`;

const SectionHeading: FunctionComponent<Props> = (props) => {
  const {
    eyebrow,
    heading,
    headingSize = "xxxlarge",
    description,
    link,
    marginBottom = "0px",
    narrow = false,
    slash,
  } = props;

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <div
        css={css`
          box-sizing: border-box;
          display: flex;
          flex-wrap: wrap;
          height: 100%;
          margin-bottom: ${marginBottom};
          width: 100%;
        `}
      >
        <div
          css={css`
            ${columnCss}
            flex: ${narrow ? "3" : "4"};
            margin-right: 10%;
            margin-top: 1rem;
          `}
        >
          <Slash slash={slash}>
            {eyebrow ? (
              <div
                css={css`
                  margin-bottom: 1.5rem;

                  @media (max-width: ${mediaTabletLandscape}) {
                    margin-bottom: 0.75rem;
                  }
                `}
              >
                <Text
                  as={"div"}
                  color={"blue"}
                  responsive={true}
                  size={"large"}
                  weight={"semibold"}
                >
                  {eyebrow}
                </Text>
              </div>
            ) : null}

            {heading?.length ? (
              <Heading as={"h3"} responsive={true} size={headingSize}>
                {heading}
              </Heading>
            ) : null}
          </Slash>
        </div>

        {description ? (
          <div
            css={css`
              ${columnCss}
              flex: 5;
              margin-right: ${narrow ? "10%" : "0"};
              padding-top: ${eyebrow ? "4.5rem" : "1.25rem"};

              @media (max-width: ${mediaTabletLandscape}) {
                padding-top: 0;
              }
            `}
          >
            <Text
              as={"div"}
              color={"midgray"}
              css={css`
                margin-bottom: 1.25rem;
              `}
              responsive={true}
              size={"large"}
            >
              {description}
            </Text>

            {link?.url ? (
              <>
                <Text
                  as={"span"}
                  color={"blue"}
                  size={"small"}
                  weight={"semibold"}
                >
                  <Anchor
                    color={"inherit"}
                    hoverColor={colorBlueDark}
                    href={link?.url}
                  >
                    {link?.label}
                    {link?.type ? (
                      <Icon
                        size={"12px"}
                        source={link?.type}
                        xPos={"16px"}
                        yPos={"1px"}
                      />
                    ) : null}
                  </Anchor>
                </Text>
              </>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SectionHeading;
