export const getLanguageCopy = (
  countryCode: string,
): { enCopy: string; foreignCopy: string } => {
  const languageCopy =
    countryCode === "JP"
      ? {
          enCopy: "Choose another language",
          foreignCopy: "別の言語を選択してください",
        }
      : {
          enCopy: "Choose another language",
          foreignCopy: "切换至另一种语言",
        };

  return languageCopy;
};
